import React from "react";
import { Grid, IconButton, Typography, Tooltip, Card, CardHeader, CardContent, TextField } from "@mui/material";
import { Business, Edit as EditIcon, Event, Save as SaveIcon } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { UpdateProject } from "../../services/UpdateProject";
import { createUpdateKV } from "../../services/CreateUpdateKV";
import { TextEntry } from "component_library";

const toDate = (dateStr) => {
  const [date] = dateStr.split("T");
  return date;
};

const isDisabled = (allowedRoles, user) => {
  var allowedRolesInt = allowedRoles.map((role) => (user[role] ? 1 : 0));
  return Math.max(...allowedRolesInt) === 0;
}

const allowedProjectUpdateKeys = ["customID", "dueDate"];

const ProjectDescription = (props) => {
  const { project, getSetProject, user, module = "" } = props;
  const [editDisabled, setEditDisabled] = useState(true);
  const [localProject, setLocalProject] = useState(project);
  const [editable, setEditable] = useState(false)
  const [suEditable, setSuEditable] = useState(false)
  const [suEditDisabled, setSuEditDisabled] = useState(true);
  const [projectUpdates, setProjectUpdates] = useState([]);
  const [keyValueUpdates, setKeyValueUpdates] = useState([]);

  useEffect(() => { 
    
    try {
      setSuEditDisabled(isDisabled(["admin","engineering"], user));
    } catch (error) {
      console.error(error)
    }

    try {
      setEditDisabled(isDisabled(["admin","engineering","production","quality","purchasing"], user));
    } catch (error) {
      console.error(error)
    }
  }, [user])

  useEffect(() => {
    setLocalProject(project)
  }, [project])
  
  const isUserHiddenRole = () => {
    var allowedRoles = [
      "admin",
      "engineering",
      "production",
      "quality",
      "purchasing",
      "allcompany",
    ];
    var allowedRolesInt = allowedRoles.map((role) => (user[role] ? 1 : 0));
    return Math.max(...allowedRolesInt) === 0;
  };

  const handleChange = (key, value) => {
    setLocalProject(localProject => ({ ...localProject, [key]: value }))
    if (allowedProjectUpdateKeys.indexOf(key) > -1) {
      let localKeys = projectUpdates;
      if( localKeys.indexOf(key) === -1 ){
        localKeys.push(key);
        setProjectUpdates(localKeys)
      }
    } else {
      let localKeys = keyValueUpdates;
      if (localKeys.indexOf(key) === -1) {
        localKeys.push(key);
        setKeyValueUpdates(localKeys);
      }
    }
  }

  const handleSave = async () => {
    setEditable(false)
    setSuEditable(false)
    if (projectUpdates.length > 0) {
      if (getSetProject) {
        await UpdateProject(localProject, projectUpdates);
      }
    } else {
      console.log('No Project Updates')
    }
    if (keyValueUpdates.length > 0) {
      if (getSetProject) {
        for (let i = 0; i < keyValueUpdates.length; i++){
          await createUpdateKV(
            keyValueUpdates[i],
            localProject[keyValueUpdates[i]],
            localProject,
            user.id);
        }
      }
    } else {
      console.log('No KV Updates')
    }
    if ((getSetProject) &&
        ((projectUpdates.length > 0) || (keyValueUpdates.length > 0))) {
      getSetProject(localProject.id)
    }
  }

  const sxStyle = {
    width: '100%',
    mb: '1em',
    '& label.Mui-disabled': {
      color: '#383838',
    },
    '& .MuiInputBase-input.MuiInput-input.Mui-disabled': {
      "-webkit-text-fill-color": '#181818',
      }
  }



  return (
    <Card raised={false} sx={{border: "none", boxShadow: "none"}}>
      <CardHeader
        title={suEditable ?
          <TextEntry
            sx={sxStyle}
            tooltip={"Project ID"}
            type="text"
            label={"Project ID"}
            variant={editable ? "outlined" : "standard"}
            size="large"
            disabled={!editable}
            value={localProject.customID}
            onChange={event => handleChange("customID", event.target.value)}
          /> :
          localProject.customID
        }
        action={
          editable ? (
            <Tooltip title="Save Project Details">
              <IconButton
                aria-label="edit project values"
                disabled={editDisabled}
                onClick={() => handleSave()}
              >
                <SaveIcon />
              </IconButton> 
            </Tooltip>
          ) : (
            <Tooltip title="Edit Project Details">
              <IconButton
                aria-label="edit project values"
                disabled={editDisabled}
                  onClick={() => {
                    setEditable(true)
                    if (!suEditDisabled) {
                      setSuEditable(true)
                    }
                  }}
              >
                <EditIcon />
              </IconButton> 
            </Tooltip>
          )
          
        }
      />
      <CardContent>

        <Grid item xs={12} md={12}>
          <Grid container spacing={0.5}>
            <Grid item xs={12} md={6} lg={4}>
              {suEditable ? 
                <TextEntry
                sx={sxStyle}
                tooltip={localProject.partnumber.numberType === "part"
                ? "Part Number"
                : "Drawing Number"}
                type="text"
                label={localProject.partnumber.numberType === "part"
                ? "Part Number"
                : "Drawing Number"}
                variant={editable ? "outlined" : "standard"}
                size="small"
                disabled={!editable}
                value={localProject.localPN ? localProject.localPN : localProject.partnumber.objectnumber}
                onChange={event => handleChange("localPN", event.target.value)}
                /> :
                
                <Tooltip title="Part Number">
                  <Typography>
                    {localProject.partnumber.numberType === "part"
                      ? "Part Number"
                      : "Drawing Number"}
                    : <b>{localProject.localPN ? localProject.localPN : localProject.partnumber.objectnumber}</b>
                  </Typography>
                </Tooltip>
              }
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Tooltip title="Due Date">
                <DatePicker 
                    variant="standard"
                    inputFormat="MM/dd/yyyy"
                    margin="normal"
                    id="duedate-picker-inline"
                    label="Due Date"
                    value={localProject.dueDate}
                    disabled={!editable}
                    onChange={date => handleChange('dueDate', date)}
                    renderInput={(params) => <TextField 
                      sx={sxStyle}
                      variant='standard' {...params} />}
                  />
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Tooltip title="Supplier">
                <Typography>
                  <Business fontSize="small" />
                  Supplier: <b>{localProject.supplier ? localProject.supplier.name : ""}</b>
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextEntry
                sx={sxStyle}
                tooltip="Number of Sample Parts"
                type="number"
                label="Qty"
                variant={editable ? "outlined" : "standard"}
                size="small"
                disabled={!editable}
                value={localProject.numberSamples}
                onChange={event => handleChange("numberSamples", event.target.value)}
              />
            </Grid>
            {module === "mtr" ? (
              <>
                <Grid item xs={12} md={6} lg={4}>
                  <TextEntry
                  sx={sxStyle}
                    tooltip="Material Part Number"
                    type="text"
                    label="Material Part Number"
                    variant={editable ? "outlined" : "standard"}
                    size="small"
                    disabled={!editable}
                    value={localProject.mpartnumber}
                    onChange={event => handleChange("mpartnumber", event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextEntry
                  sx={sxStyle}
                    tooltip="Heat Number"
                    type="text"
                    label="Heat Number"
                    variant={editable ? "outlined" : "standard"}
                    size="small"
                    disabled={!editable}
                    value={localProject.heatNumber}
                    onChange={event => handleChange("heatNumber", event.target.value)}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} md={6} lg={4}>          
              <TextEntry
                sx={sxStyle}
                tooltip="PO Number"
                type="text"
                label="PO Number"
                variant={editable ? "outlined" : "standard"}
                size="small"
                disabled={!editable}
                value={localProject.poNumber}
                onChange={event => handleChange("poNumber", event.target.value)}
              />
            </Grid>
            {module === "fai" &&
            project.additionalSamplesRequired === "Yes" &&
            !isUserHiddenRole() ? (
              <Grid item xs={12} md={6} lg={4}>
                <Tooltip title="Disposition">
                  <Typography>
                    Disposition:{" "}
                    <b>
                      {project.sampleLotDisposition
                        ? project.sampleLotDisposition
                        : project.additionalSamplesRequired}
                    </b>
                  </Typography>
                </Tooltip>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TextEntry
                sx={sxStyle}
                tooltip="Description"
                type="text"
                label="Description"
                variant={editable ? "outlined" : "standard"}
                size="small"
                disabled={!editable}
                value={localProject.description}
                onChange={event => handleChange("description", event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

    </Card>
  );
};

export default ProjectDescription;
