export const projectConfigs = [
  {
    id: "fai",
    name: "fai",
    longName: "First Article Inspection",
    module: "fai",
    roles: {
      supplier: {
        name: "Supplier",
        description:
          "Responsible for completing sample data collection and acknowledging requirements.",
        show: true,
      },
      admin: {
        name: "Admin",
        description:
          "Has full access to the project and can step the project through all steps regardless of responsible party.",
        show: true,
      },
      purchasing: {
        name: "Purchasing",
        description:
          "Can update the project details and team members. Responsible for sending steps to the supplier.",
        show: true,
      },
      engineering: {
        name: "Engineering",
        description:
          "Can create and modify requirements. Responsible for signing-off on inspection.",
        show: true,
      },
      quality: {
        name: "Quality",
        description:
          "Can create and update an inspection. Responsible for completing inspection for Engineering sign-off.",
        show: true,
      },
      production: {
        name: "Production",
        description: "Has read only access to the project.",
        show: true,
      },
      allcompany: {
        name: "All Company",
        description: "Can view the project.",
        show: false,
      },
    },
    steps: {
      create: {
        label: "Create FAI",
        phase: "create",
        addRoles: {
          adminUser: ["admin", "engineering"],
          editUser: ["purchasing"],
          viewUser: ["quality", "production", "allcompany"],
        },
        initialLinkedObjects: [
          {
            type: "requirement",
            name: "Drawing",
            description: "Dimensions of drawings of the part",
          },
          {
            type: "requirement",
            name: "Material Spec(s)",
            description: "Material specification(s) of the part",
          },
          {
            type: "requirement",
            name: "Label Spec",
            description: "Carton label specification",
          },
        ],
        keyValuePairs: [
          {
            key: "numberSamples",
            label: "Number of Samples",
            type: "number",
            description: "Number of samples required for the FAI.",
          },
          {
            key: "poNumber",
            label: "PO Number",
            type: "text",
            description: "The PO number associated with the FAI.",
          },
          {
            key: "description",
            label: "Description",
            type: "text",
            description: "A brief description about the project.",
          },
        ],
        nextStep: "req_define",
      },
      req_define: {
        label: "Define Requirements",
        phase: "requirements",
        complete: false,
        nextStep: "req_p2s",
        lastStep: "assign_team",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: [],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Send to Purchasing",
          body: "Does Engineering sign-off on the above requirements and want to send to purchasing and then supplier?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
              modals: [
                {
                  key: "req_define_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      req_p2s: {
        label: "Send Requirements",
        phase: "requirements",
        complete: false,
        nextStep: {
          approve: "req_acknowledge",
          reset: "req_define",
        },
        lastStep: "req_define",
        approvalRoles: ["admin", "purchasing"],
        addRoles: {
          adminUser: [],
          editUser: ["purchasing", "quality"],
          viewUser: [],
        },
        notifyRoles: [],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Send to Supplier",
          body: "Does Purchasing want to send to Supplier for acknowledgement?",
          actions: {
            reset: {
              label: "Reset to Define Requirements",
              icon: "Undo",
              color: "secondary",
              stepDirection: "forward",
              tooltip:
                "Reset and adjust requirements. This will require all completed steps to be re-completed",
              allowedRoles: ["engineering", "admin"],
              nSteps: 1,
              nextStepOption: "reset",
              modals: [
                {
                  key: "req_p2s_resetReason",
                  title: "Reset to Define Requirements Reasons",
                  description:
                    "Please enter your reasons for reseting to define requirements,",
                  notifyRoles: [
                    "engineering",
                    "purchasing",
                    "admin",
                    "quality",
                  ],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              tooltip: "Send to the supplier for acknowledgement",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "req_p2s_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["supplier"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      req_acknowledge: {
        label: "Acknowledge Requirements",
        phase: "requirements",
        complete: false,
        nextStep: {
          approve: "sample_upload",
          reject: "req_define",
          reset: "req_define",
        },
        lastStep: "req_p2s",
        approvalRoles: ["admin", "supplier"],
        addRoles: {
          adminUser: [],
          editUser: [],
          viewUser: ["supplier", "production"],
        },
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Supplier Acknowledgement",
          body: "Does supplier acknowledge and agree to the above requirements?",
          actions: {
            reset: {
              label: "Reset to Define Requirements",
              icon: "Undo",
              color: "secondary",
              stepDirection: "forward",
              tooltip:
                "Reset and adjust requirements. This will require all completed steps to be re-completed",
              allowedRoles: ["engineering", "admin"],
              nSteps: 1,
              nextStepOption: "reset",
              modals: [
                {
                  key: "req_p2s_resetReason",
                  title: "Reset to Define Requirements Reasons",
                  description:
                    "Please enter your reasons for reseting to define requirements,",
                  notifyRoles: [
                    "engineering",
                    "purchasing",
                    "admin",
                    "quality",
                  ],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "reject",
              modals: [
                {
                  key: "req_acknowledge_rejectReasons",
                  title: "Reject Reasons",
                  description:
                    "Please enter your reasons for rejecting the requirements,",
                  notifyRoles: ["engineering", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "req_acknowledge_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["supplier"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      sample_upload: {
        label: "Upload Sample Data",
        phase: "samples",
        complete: false,
        nextStep: {
          approvalRequested: "sample_dataaccept",
          noData: "sample_dataaccept",
          reset: "req_define",
        },
        lastStep: "req_acknowledge",
        approvalRoles: ["admin", "supplier"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Submit Documents",
          body: "Does Supplier want to submit documents for quality review with or without data?",
          actions: {
            reset: {
              label: "Reset to Define Requirements",
              icon: "Undo",
              color: "secondary",
              stepDirection: "forward",
              tooltip:
                "Reset and adjust requirements. This will require all completed steps to be re-completed",
              allowedRoles: ["engineering", "admin"],
              nSteps: 1,
              nextStepOption: "reset",
              modals: [
                {
                  required: true,
                  key: "req_p2s_resetReason",
                  title: "Reset to Define Requirements Reasons",
                  description:
                    "Please enter your reasons for reseting to define requirements,",
                  notifyRoles: [
                    "engineering",
                    "purchasing",
                    "admin",
                    "quality",
                  ],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            noData: {
              label: "Submit Without Data",
              icon: "Forward",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "noData",
            },
            approvalRequested: {
              label: "Submit Uploaded Data",
              icon: "Forward",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approvalRequested",
            },
          },
        },
      },
      sample_dataaccept: {
        label: "Accept Sample Data",
        phase: "samples",
        complete: false,
        nextStep: {
          approve: "sample_ship",
          reject: "sample_upload",
        },
        lastStep: "sample_upload",
        approvalRoles: ["admin", "engineering", "quality"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Submit Documents",
          body: "Does Quality or Engineering approve of submitted documents?",
          actions: {
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "reject",
              modals: [
                {
                  key: "sample_dataaccept_rejectReasons",
                  title: "Reject Reasons",
                  description:
                    "Please enter your reasons for rejecting the sample data.",
                  notifyRoles: ["supplier", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "sample_dataaccept_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["production", "quality", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      sample_ship: {
        label: "Ship Samples",
        phase: "samples",
        complete: false,
        nextStep: "sample_receive",
        lastStep: "sample_dataaccept",
        approvalRoles: ["admin", "supplier"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Submit Shipping Information",
          body: "Does Supplier want to submit shipping information?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
            },
          },
        },
      },
      sample_receive: {
        label: "Receive Samples",
        phase: "samples",
        complete: false,
        nextStep: "fai_perform",
        lastStep: "sample_ship",
        approvalRoles: ["admin", "production", "quality"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Receive Samples",
          body: "Does Production or Quality want to receive shipped samples?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
            },
          },
        },
      },
      fai_perform: {
        label: "Perform FAI",
        phase: "inspection",
        complete: false,
        nextStep: "fai_review",
        lastStep: "sample_receive",
        approvalRoles: ["admin", "quality"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Submit FAI",
          body: "Does Quality want to submit FAI results to Engineering for review?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
              modals: [
                {
                  key: "fai_perform_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["engineering"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      fai_review: {
        label: "Review FAI",
        phase: "inspection",
        complete: false,
        addRoles: {
          adminUser: [],
          editUser: ["supplier"],
          viewUser: [],
        },
        nextStep: {
          approve: "fai_acknowledge_approve",
          reject: "rejected",
          return: "fai_perform",
        },
        lastStep: "fai_perform",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Review FAI",
          body: "Does Engineering approve of FAI?",
          actions: {
            return: {
              label: "Return to Quality",
              icon: "Undo",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "return",
            },
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "reject",
              modals: [
                {
                  key: "rejectReason",
                  title: "Rejection Reason",
                  description:
                    "Select the reason why the FAI is being rejected.",
                  notifyRoles: ["purchasing", "supplier", "quality"],
                  responseType: "multiSelect",
                  options: [
                    "1 - Dimension out of tolerance (non-threading/non-screw)",
                    "2 - Body threads out of tolerance",
                    "3 - Screw threads out of tolerance",
                    "4 - Screw dimension(s) out of tolerance (non-threading)",
                    "5 - Failed Torque",
                    "6 - Incorrect label",
                    "7 - Box qty wrong",
                    "8 - Box weight too heavy",
                    "9 - Excessive burrs",
                    "10 - Plating coverage/flaking",
                    "11 - Plating type wrong",
                    "12 - Plating thickness",
                    "13 - Stamping illegible",
                    "14 - Stamping wrong",
                    "15 - Stamping missing",
                    "16 -	Stuffer sheet wrong or missing",
                    "17 - Too hard (annealing)",
                    "18 - Too soft (annealing)",
                    "19 - Wrong components assembled",
                    "20 - Wrong raw material used",
                    "21 - Porosity",
                    "22 - Oily Parts",
                    "23 - Mixed product",
                    "24 - Insufficient packaging (shipping damage)",
                    "25 - Not to current print revision (Part made to an old revison)",
                    "26 - Corrosion/oxidation",
                    "27 - Rough surface finish",
                    "28 - Vendor Shipped Wrong Parts",
                    "29 - Feature not defined by drawing",
                    "30 - Need material certs",
                    "31 - Oxide inhibitor - Too much or too little",
                    "32 - Other",
                  ],
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
                {
                  key: "additionalSamplesRequired",
                  title: "Additional Samples Required?",
                  description:
                    "When the supplier responds to non-conformances, are additional samples required?",
                  notifyRoles: ["purchasing", "supplier", "quality"],
                  responseType: "radioButtons",
                  radioOptions: ["Yes", "No"],
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
                {
                  key: "sampleLotDisposition",
                  title: "Sample Lot Disposition",
                  description: "What should be done with the samples?",
                  notifyRoles: ["purchasing", "quality", "production"],
                  responseType: "radioButtons",
                  hiddenRoles: ["supplier"],
                  radioOptions: [
                    "Sample Order: Save for future reference",
                    "Production Order: Use-as-is",
                    "Production Order: Rework Required",
                    "Production Order: Reject",
                  ],
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
            },
          },
        },
      },
      fai_acknowledge_approve: {
        label: "Acknowledge FAI Results",
        phase: "inspection",
        complete: false,
        nextStep: {
          approve: "approved",
        },
        lastStep: "fai_review",
        approvalRoles: ["admin", "supplier"],
        addRoles: {
          adminUser: [],
          editUser: [],
          viewUser: [],
        },
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Supplier Acknowledgement",
          body: "Does supplier acknowledge receipt of the FAI results?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [],
            },
          },
        },
      },
      rejected: {
        label: "Open CAR",
        phase: "inspection",
        complete: false,
        nextStep: "fai_acknowledge_car",
        lastStep: "fai_review",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing", "quality", "supplier"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Close FAI",
          body: "Does Engineering wish to close FAI?",
          actions: {
            close: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
            },
          },
        },
      },
      fai_acknowledge_car: {
        label: "Acknowledge FAI Closed with CAR",
        phase: "inspection",
        complete: false,
        nextStep: {
          approve: "rejected_car",
        },
        lastStep: "rejected",
        approvalRoles: ["admin", "supplier"],
        addRoles: {
          adminUser: [],
          editUser: [],
          viewUser: [],
        },
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Supplier Acknowledgement",
          body: "Does supplier acknowledge receipt of the FAI results?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [],
            },
          },
        },
      },
      approved: {
        label: "Closed",
        phase: "inspection",
        complete: false,
        nextStep: null,
        lastStep: "fai_review",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing"],
        finalStep: true,
        defaultFinalStep: true,
        optionalStep: true,
      },
      rejected_closed: {
        label: "Closed (CAR)",
        phase: "inspection",
        complete: false,
        nextStep: null,
        lastStep: "rejected",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing"],
        finalStep: true,
        defaultFinalStep: false,
        optionalStep: true,
      },
    },
  },
  {
    id: "mtr",
    name: "mtr",
    longName: "Material Test Report",
    module: "mtr",
    roles: {
      supplier: {
        name: "Supplier",
        description:
          "Responsible for completing sample data collection and acknowledging requirements.",
        show: true,
      },
      admin: {
        name: "Admin",
        description:
          "Has full access to the project and can step the project through all steps regardless of responsible party.",
        show: true,
      },
      purchasing: {
        name: "Purchasing",
        description:
          "Can update the project details and team members. Responsible for sending steps to the supplier.",
        show: true,
      },
      engineering: {
        name: "Engineering",
        description:
          "Can create and modify requirements. Responsible for signing-off on inspection.",
        show: true,
      },
      quality: {
        name: "Quality",
        description:
          "Can create and update an inspection. Responsible for completing inspection for Engineering sign-off.",
        show: true,
      },
      production: {
        name: "Receiving",
        description: "Can update the MatCert Pro step.",
        show: true,
      },
      allcompany: {
        name: "All Company",
        description: "Can view the project.",
        show: false,
      },
    },
    steps: {
      create: {
        label: "Create MTR",
        phase: "create",
        addRoles: {
          adminUser: ["admin", "engineering"],
          editUser: ["purchasing", "quality"],
          viewUser: ["production", "allcompany"],
        },
        initialLinkedObjects: [
          {
            type: "requirement",
            name: "Drawing",
            description: "Dimensions of drawings of the part",
          },
          {
            type: "requirement",
            name: "Material Spec(s)",
            description: "Material specification(s) of the part",
          },
        ],
        keyValuePairs: [
          {
            key: "mpartnumber",
            label: "Material Part Number",
            type: "autocomplete",
            description: "Material Part Number associated with this MTR",
          },
          {
            key: "quantity",
            label: "Quantity",
            type: "number",
            description: "Quantity requested - measured by the unit selected.",
          },
          {
            key: "quantityUnit",
            label: "Quantity Unit of Measure",
            type: "select",
            options: [
              "Inches",
              "Feet",
              "Pounds",
              "Centimeters",
              "Meters",
              "Kilograms",
            ],
          },
          {
            key: "poNumber",
            label: "PO Number",
            type: "text",
            description: "The PO number associated with the MTR.",
          },
          {
            key: "heatNumber",
            label: "Heat Number",
            type: "text",
            description: "The heat number associated with the MTR.",
          },
          {
            key: "description",
            label: "Description",
            type: "text",
            description:
              "Description of the MTR to help understand the purpose of the MTR.",
          },
        ],
        nextStep: "req_define",
      },
      req_define: {
        label: "Define Requirements",
        phase: "requirements",
        complete: false,
        nextStep: {
          send: "req_acknowledge",
          approve: "req_eng_approve",
          directUpload: "documents_submit",
        },
        lastStep: null,
        approvalRoles: ["admin", "engineering", "purchasing"],
        notifyRoles: [],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Send Forward to Next Step",
          body: "Does this material test request need engineering approval, or can it be sent to the supplier?",
          actions: {
            send: {
              label: "Send to Supplier",
              icon: "Send",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "send",
              modals: [
                {
                  key: "requirements_supplier_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions for the supplier in the field below and then press Submit",
                  notifyRoles: ["supplier"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            directUpload: {
              label: "Skip to Cert Upload",
              icon: "Send",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "directUpload",
              modals: [],
            },
            approve: {
              label: "Engineering Approval",
              icon: "PriorityHigh",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "requirements_engineeringApproval_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions for engineering in the field below and then press Submit",
                  notifyRoles: ["engineering"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      req_eng_approve: {
        label: "Engineering Approval",
        phase: "requirements",
        complete: false,
        nextStep: {
          approve: "req_p2s",
          reject: "req_define", //what happens here?
        },
        lastStep: null,
        approvalRoles: ["admin", "engineering"],
        notifyRoles: [],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Engineer Approval",
          body: "Does engineering approve?",
          actions: {
            reject: {
              label: "Reject",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "reject",
              modals: [
                {
                  key: "req_eng_approve_rejectreasons",
                  title: "Reject Reasons",
                  description:
                    "Enter reasons for rejecting requirements in the field below and then press Submit",
                  notifyRoles: ["purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Approve",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "req_eng_approve_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions for purchasing in the field below and then press Submit",
                  notifyRoles: ["purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      req_p2s: {
        label: "Send Requirements to Supplier",
        phase: "requirements",
        complete: false,
        nextStep: "req_acknowledge",
        lastStep: "req_define",
        approvalRoles: ["admin", "purchasing"],
        notifyRoles: ["quality"],
        addRoles: {
          adminUser: [],
          editUser: ["quality", "purchasing", "production"],
          viewUser: [],
        },
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Send to Supplier",
          body: "Does Purchasing want to send to Supplier for acknowledgement?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
              modals: [
                {
                  key: "req_p2s_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions for supplier in the field below and then press Submit",
                  notifyRoles: ["supplier"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      req_acknowledge: {
        label: "Acknowledge Requirements",
        phase: "requirements",
        complete: false,
        nextStep: {
          approve: "documents_submit",
          disapprove: "req_define",
        },
        lastStep: "req_p2s",
        approvalRoles: ["admin", "supplier"],
        optionalApprovalRoles: ["purchasing"],
        notifyRoles: [],
        addRoles: {
          adminUser: [],
          editUser: [],
          viewUser: ["supplier", "production"],
        },
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Supplier Acknowledgement",
          body: "Does supplier acknowledge and agree to the above requirements?",
          actions: {
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "disapprove",
              modals: [
                {
                  key: "req_acknowledge_rejectreasons",
                  title: "Reject Reasons",
                  description:
                    "Enter reasons for rejecting requirements in the field below and then press Submit",
                  notifyRoles: ["engineering", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
            },
          },
        },
      },
      documents_submit: {
        label: "Submit Documents",
        phase: "data_upload",
        complete: false,
        nextStep: "record_create",
        lastStep: "req_acknowledge",
        approvalRoles: ["admin", "supplier"],
        optionalApprovalRoles: ["purchasing"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Submit Documents",
          body: "Does Supplier want to submit documents for quality review?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
            },
          },
        },
      },
      record_create: {
        label: "Create Record in MatCertPro",
        phase: "data_upload",
        complete: false,
        nextStep: "review_quality",
        lastStep: "req_acknowledge",
        approvalRoles: ["admin", "production"],
        optionalApprovalRoles: ["quality"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Mat Cert Pro Record Created",
          body: "Is the Mat Cert Pro record created?",
          actions: {
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: null,
            },
          },
        },
      },
      review_quality: {
        label: "Quality Review",
        phase: "review",
        complete: false,
        nextStep: {
          approve: "approved",
          disapprove: "review_engineering",
        },
        lastStep: "record_create",
        approvalRoles: ["admin", "quality"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: false,
        workflow: {
          title: "Review Submitted Documents",
          body: "Does Quality accept submitted documents?",
          actions: {
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "disapprove",
              modals: [
                {
                  key: "review_quality_rejectReasons",
                  title: "Reject Reasons",
                  description:
                    "Please enter your reasons for rejecting the documents.",
                  notifyRoles: ["engineering", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "review_quality_specialInstructions",
                  title: "Special Instructions",
                  description:
                    "Enter any special instructions in the field below and then press Submit",
                  notifyRoles: ["production", "engineering", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      review_engineering: {
        label: "Engineering Review",
        phase: "review",
        complete: false,
        nextStep: {
          approve: "approved",
          disapprove: "rejected",
        },
        lastStep: "review_quality",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Review Submitted Documents",
          body: "Does Engineering accept submitted documents?",
          actions: {
            reject: {
              label: "No",
              icon: "ThumbDown",
              color: "secondary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "disapprove",
              modals: [
                {
                  key: "review_engineering_rejectReasons",
                  title: "Reject Reasons",
                  description:
                    "Please enter your reasons for rejecting the documents.",
                  notifyRoles: ["supplier", "quality", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
            approve: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
              nextStepOption: "approve",
              modals: [
                {
                  key: "review_engineering_specialInstructions ",
                  title: "Reject Reasons",
                  description:
                    "Please enter your reasons for rejecting the documents.",
                  notifyRoles: ["supplier", "quality", "purchasing"],
                  responseType: "textField",
                  continueLabel: "Submit",
                  continueIcon: "Forward",
                  continueColor: "primary",
                  cancelLabel: "Cancel",
                  cancelIcon: "Cancel",
                  cancelColor: "secondary",
                },
              ],
            },
          },
        },
      },
      rejected: {
        label: "Open CAR",
        phase: "review",
        complete: false,
        nextStep: "rejected_closed",
        lastStep: "review_engineering",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing", "quality", "supplier"],
        finalStep: false,
        defaultFinalStep: false,
        optionalStep: true,
        workflow: {
          title: "Close MTR",
          body: "Does Engineering wish to close MTR?",
          actions: {
            close: {
              label: "Yes",
              icon: "ThumbUp",
              color: "primary",
              stepDirection: "forward",
              nSteps: 1,
            },
          },
        },
      },
      approved: {
        label: "MTR Approved",
        phase: "review",
        complete: false,
        nextStep: null,
        lastStep: "review_engineering",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing", "quality", "supplier"],
        finalStep: true,
        defaultFinalStep: true,
        optionalStep: true,
      },
      rejected_closed: {
        label: "Closed (CAR)",
        phase: "review",
        complete: false,
        nextStep: null,
        lastStep: "rejected",
        approvalRoles: ["admin", "engineering"],
        notifyRoles: ["purchasing", "quality", "supplier"],
        finalStep: true,
        defaultFinalStep: false,
        optionalStep: true,
      },
    },
  },
];

export default projectConfigs;
