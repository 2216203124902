import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AttachmentList from "../../Utilities/Attachment/Attachment";
import FileUploadBox from "../../Utilities/FileUpload/FileUploadBox";
import UserContext from "../../../contexts/UserContext";
import CurrentProjectContext from "../../../contexts/CurrentProjectContext";
import { API } from "aws-amplify";
import { formatProject } from "../../Utilities/FormatProject";
import * as getProjectquery from "../../../graphql/getProject";
import * as mutations from "../../../graphql/mutations";
import CompleteStep from "../../Base/CompleteStep";
import projectConfigs from "../../../constants/projectConfigs.js";

var Steps = { ...projectConfigs.find((item) => item.name === "fai").steps };
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  card: {
    width: "100%",
    marginTop: "1em",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Container maxWidth="lg">{children}</Container>}
    </div>
  );
}

const Samples = (props) => {
  const { value, index, getSetProject, ...other } = props;
  const { user } = useContext(UserContext);
  const { project, setProject } = useContext(CurrentProjectContext);
  const classes = useStyles();
  const [attachments, setAttachments] = useState([]);
  const [shippingInformation, setShippingInformation] = useState("");
  const [shippingHidden, setShippingHidden] = useState(false);
  const [uploads, setUploads] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (project.deliverable) {
        if (
          project.deliverable.items.findIndex(
            (item) => item.name === "uploads"
          ) === -1
        ) {
          const newDeliverable = {
            projectID: project.id,
            name: "uploads",
            description: "Placeholder for sample uploads",
            creatorID: user.id,
            lastModifierID: user.id,
          };
          await API.graphql({
            query: mutations.createDeliverable,
            variables: { input: newDeliverable },
          }).then((response) => response.data.createDeliverable.id);
          var tmpProject = await API.graphql({
            query: getProjectquery.getProject,
            variables: { id: project.id },
          });
          tmpProject = formatProject(tmpProject.data.getProject, Steps);
          setProject(tmpProject);
          setAttachments([]);
        } else {
          const tmpuploads = project.deliverable.items.filter(
            (item) => item.name === "uploads"
          )[0];
          setAttachments(tmpuploads.attachments.items);
          setUploads(tmpuploads);
        }
      }
    };
    fetchData();
    setShippingInformation(project.shippingInformation);
  }, [project, setProject, user]);

  useEffect(() => {
    if (project.sample_dataaccept_required) {
      if (project.status.statusState === "sample_upload") {
        setShippingHidden(true);
      } else if (project.status.statusState === "sample_dataaccept") {
        setShippingHidden(true);
      } else if (project.status.statusState === "sample_receive") {
        setShippingHidden(false);
      } else {
        setShippingHidden(false);
      }
    } else {
      setShippingHidden(false);
    }
  }, [project.sampleApprovalReq, project]);

  const handleShippingBlur = async () => {
    var kv = project.keyvalues.items.find(
      (item) => item.key === "shippingInformation"
    );
    if (kv) {
      const updateKV = {
        id: kv.id,
        value: shippingInformation,
        lastModifierID: user.id,
        _version: kv._version,
      };
      await API.graphql({
        query: mutations.updateKeyvalues,
        variables: { input: updateKV },
      }).then((response) => getSetProject(project.id));
    } else {
      const newKV = {
        projectID: project.id,
        key: "shippingInformation",
        value: shippingInformation,
        creatorID: user.id,
        lastModifierID: user.id,
      };
      await API.graphql({
        query: mutations.createKeyvalues,
        variables: { input: newKV },
      }).then((response) => getSetProject(project.id));
    }
  };

  const handleShippingChange = (event) => {
    setShippingInformation(event.target.value);
  };

  return (
    <TabPanel
      value={value}
      index={index}
      padding={"1em"}
      className={classes.root}
    >
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">Supplier Data for Sample Parts</Typography>
          <Grid container spacing={1} className={classes.card}>
            {attachments
              .filter((file) => file.attachment !== null)
              .filter((file) => file.attachment._deleted !== true)
              .map((file) => (
                <AttachmentList
                  file={file.attachment}
                  getSetProject={() => getSetProject(project.id)}
                  deleteAllowed={
                    (project.permissions.edit.indexOf(user.id) >= 0) &
                    (project.activeState === "sample_upload")
                  }
                />
              ))}
            {(project.permissions.edit.indexOf(user.id) >= 0) &
            (project.activeState === "sample_upload") ? (
              <FileUploadBox
                attachmentUse="deliverable"
                parentID={uploads.id}
                Steps={Steps}
              />
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      {shippingHidden ? null : (
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5">Shipping Information</Typography>

            <TextField
              value={shippingInformation}
              onChange={handleShippingChange}
              style={{ minWidth: "50%", marginTop: "1em" }}
              disabled={
                !(
                  (project.permissions.edit.indexOf(user.id) >= 0) &
                  (project.activeState === "sample_upload" ||
                    project.activeState === "sample_ship")
                )
              }
              id="shipping-info"
              label="Shipping Information"
              variant="outlined"
              onBlur={handleShippingBlur}
            />
          </CardContent>
        </Card>
      )}

      {project.activePhase === "samples" ? (
        <CompleteStep
          getSetProject={() => getSetProject(project.id)}
          project={project}
          user={user}
          {...other}
        />
      ) : null}
    </TabPanel>
  );
};

export default Samples;
