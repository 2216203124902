import React from 'react';
import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  xsmall: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const FileAvatar = (props) => {
  const { filetype, size='large', opacity } = props
  const classes = useStyles();
  var assetSource = '/assets/file.png'

  if (filetype === 'image/png') {
    assetSource = '/assets/png.png'
  } else if (filetype === "image/jpeg") {
    assetSource = '/assets/jpg.png'
  } else if (filetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
    assetSource = '/assets/pptx.png'
  } else if (filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    assetSource = '/assets/docx.png'
  } else if (filetype === 'application/pdf') {
    assetSource = '/assets/pdf.png'
  }

  return (
    <Avatar sx={{...classes[size], opacity: opacity ? opacity : 1}} aria-label="filetype" variant="square" src={assetSource} />
  )
  
}

export default FileAvatar;